/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Options Page: https://www.creative-tim.com/Options/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'

// Material Dashboard 2 React components
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'

// Material Dashboard 2 React example components
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'examples/Navbars/DashboardNavbar'
import Footer from 'examples/Footer'
import * as yup from 'yup'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

// Data
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import MDButton from 'components/MDButton'
import { IconButton, InputAdornment, Modal, TextField } from '@mui/material'
import MDInput from 'components/MDInput'
import { Search } from '@mui/icons-material'
import { useAppServices } from 'hook/services'
import { useFormik } from 'formik'
import DataTable from 'examples/Tables/DataTable'
import projectsTableData from '../data/projectsTableData'
import authorsTableData from '../data/authorsTableData'
import OptionCreate from './create'
import Multiselect from './components/Multiselect'
import ReactSelect from 'react-select'

// const style = {
//   position: 'absolute',
//   top: '50%',
//   left: '50%',
//   transform: 'translate(-50%, -50%)',
//   width: 400,
//   bgcolor: 'background.paper',
//   border: '2px  #000',
//   boxShadow: 24,
//   p: 4,
// }
// const initstate = {
//   name: '',
// }
// const DocsValidate = yup.object({
//   name: yup.string().required().label('Name'),
// })
function Options() {
  const { columns, rows, getOptions, prices } = authorsTableData()
  const [processing, setProcessing] = useState(false)

  const { columns: pColumns, rows: pRows } = projectsTableData()
  const navigate = useNavigate()
  const [open, setOpen] = useState(false)
  const AppService = useAppServices()
  const [defaultselected,setDefaultselected]=useState("")
  const [optionsdrp, setoptionsdrp] = useState(false)
  const getOptionsdropdown = async () => {
    const { response } = await AppService.modalcreate.Get()
    if (response) {
      let object = {}
      response.data.forEach((item, index) => {
        object[index] = { label: item.name, value: item.name }
      })
      setoptionsdrp(object)
      setDefaultselected(response.default_modal)
    }
  }
  
  const handleClose = () => {
    getOptions()
    getOptionsdropdown();
    setOpen(false)
  }

  const handleOnChange =async(selectedItems) => {
   const  payload=selectedItems;
    const { response } = await AppService.modalcreate.modalsetdefault({
      payload,
      toaster: true,
    })
    if (response) {
     console.log(defaultselected)
    }
  };

useEffect(() => {
  getOptionsdropdown();
}, [])
 
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                 
                <MDTypography variant="h6" color="white">
                  Model setting
                </MDTypography>
               
                  
                  <div className='px-3 d-flex' >
                  {defaultselected &&
                  <ReactSelect
                    options={Object.values(optionsdrp)}
                    defaultValue={{ label: defaultselected, value:defaultselected }}
                    onChange={handleOnChange}
                    className='mx-2'
                  />
                
                }
                <MDButton
                    onClick={() => setOpen(true)}
                    sx={{
                      marginLeft: 'auto',
                      // '10px'
                    }}
                    variant="outlined"
                  >
                    Create Modal
                  </MDButton>
                  <OptionCreate open={open} onClose={handleClose} options={rows} prices={prices} />

                  </div>
                
              </MDBox>
              <DndProvider backend={HTML5Backend}>
                <MDBox pt={3}>
                  <DataTable
                    table={{ columns, rows }}
                    isSorted={false}
                    entriesPerPage={false}
                    showTotalEntries={false}
                    noEndBorder
                  />
                </MDBox>
              </DndProvider>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  )
}

export default Options
