/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 React components
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import { useAppServices } from 'hook/services'
import { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import MDButton from 'components/MDButton'
import Swal from 'sweetalert2'
import { Icon } from '@mui/material'
import Category from '../Category/create'

export default function data() {
  const AppService = useAppServices()
  const [loader, setLoader] = useState(true)
  const [docs, setDocs] = useState([])
  const [open, setOpen] = useState(false)
  const navigate = useNavigate()

  const [selected, setSelected] = useState()
  
  const handleOnClick = (item, idx) => {
    console.log(item)
    setOpen(idx)
    setSelected(item)
  }
  const handleDeleteCategory = async (id, idx) => {
    Swal.fire({
      title: 'Warning',
      text: 'Are you sure you want to delete?',
      showCancelButton: true,
      showConfirmButton: true,
      confirmButtonText: 'Yes',
    }).then(async (res) => {
      if (!res.isConfirmed) return ''
      const { response } = await AppService.agency_articles_category.delete({
        query: `_id=${id}`,
        toaster: true,
      })
      if (response) {
        docs.splice(idx, 1)
        setDocs([...docs])
      }
    })
  }
  const getDocs_Cat = async () => {
    const { response } = await AppService.agency_articles.get_filter_cat({ query: 'type=super-admin' })
    console.log(response, 'response')
    if (response) setDocs(response.data)
    setLoader(false)
  }

  const onLoad = () => {
    getDocs_Cat()
  }
  useEffect(onLoad, [])

  const handleClose = () => {
    setOpen(false)
    getDocs_Cat()
  }

  return {
    columns: [
      { Header: '', accessor: 'order', width: '10%', align: 'left' },
      { Header: 'name', accessor: 'name', align: 'left' },
      { Header: 'action', accessor: 'action', align: 'center' },
    ],

    rows: docs.map((item, idx) => ({
      order: (
        <MDBox sx={{ cursor: 'pointer' }}>
          <Icon>menu</Icon>
        </MDBox>
      ),
      name: (
        <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
          {item.name}
        </MDTypography>
      ),
      action: (
        <>
          <MDButton
            onClick={() => handleOnClick(item, idx)}
            variant="gradient"
            color="info"
            style={{ marginRight: '10px' }}
          >
            Edit{' '}
          </MDButton>
          <MDButton
            variant="gradient"
            color="info"
            style={{ marginRight: '10px' }}
            onClick={() => handleDeleteCategory(item._id, idx)}
          >
            delete
          </MDButton>
          <MDButton
            variant="gradient"
            color="info"
            style={{ marginRight: '10px' }}
            onClick={() => navigate('./subcategory', { state: item })}
          >
            view
          </MDButton>
          {selected == item && (
            <Category category={selected} open={open === idx} onClose={handleClose} />
          )}
        </>
      ),
    })),
    getDocs_Cat,
  }
}
