import MDButton from 'components/MDButton'
import React, { useEffect, useState } from 'react'
import MDModal from 'components/MDModal'
import MDBox from 'components/MDBox'
import Grid from '@mui/material/Grid'
import MDTypography from 'components/MDTypography'
import { Edit } from '@mui/icons-material'
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  TextareaAutosize,
} from '@mui/material'
import { useAppServices, useUploadImage } from 'hook/services'
import { useUserInfo } from 'context/user'
import FormField from 'components/FormField'
import MDInput from 'components/MDInput'
import Multiselect from '../CreateModal/components/Multiselect'
import RadioInput from 'components/Radio/RadioInput'

const ViewModal = ({ products, data, handleRefresh, Categories }) => {
  const [openViewProduct, setOpenViewProduct] = useState(false)
  console.log(data, 'datadatadatadata')
  const handlemodal = () => {
    setOpenViewProduct(true)
  }
  useEffect(async () => { }, [])
  function ViewProduct({ open, onClose }) {
    return (
      <MDModal
        open={open}
        onClose={onClose}
        width={600}
        height={600}
        maxHeight={600}
        overflow="auto"
      >
        <MDBox>
          <MDTypography variant="h5" mb={2}>
            Customer Details
          </MDTypography>
          {/* <MDBox mb={2} ml={1} display="flex" alignItems="center">
            <Stack direction="row" alignItems="center" spacing={2}>
              <Button variant="contained" component="label" sx={{ color: '#fff' }}>
                Upload Image +
                <input hidden name="image" type="file" />
              </Button>
            </Stack>
          </MDBox> */}
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <MDBox>
                <MDTypography variant="button" sx={{ fontSize: '14px' }} mb={1}>
                  User Name:
                </MDTypography>

                <MDTypography variant="button" sx={{ fontSize: '14px', fontWeight: '500' }} mb={1} ml={5}>
                  {data.username}
                </MDTypography>
              </MDBox>
            </Grid>
            <Grid item xs={12}>
              <MDBox>
                <MDTypography variant="button" sx={{ fontSize: '14px' }} mb={1}>
                  User Email:
                </MDTypography>

                <MDTypography variant="button" sx={{ fontSize: '14px', fontWeight: '500' }} mb={1} ml={5}>
                  {data.email}
                </MDTypography>
              </MDBox>
            </Grid>
            <Grid item xs={12}>
              <MDBox>
                <MDTypography variant="button" sx={{ fontSize: '14px' }} mb={1}>
                  Company Name:
                </MDTypography>

                <MDTypography variant="button" sx={{ fontSize: '14px', fontWeight: '500' }} mb={1} ml={5}>
                  {data.agency_id?.agency_profile?.name}
                </MDTypography>
              </MDBox>
            </Grid>
            <Grid item xs={12}>
              <MDBox>
                <MDTypography variant="button" sx={{ fontSize: '14px' }} mb={1}>
                  Company email:
                </MDTypography>

                <MDTypography variant="button" sx={{ fontSize: '14px', fontWeight: '500' }} mb={1} ml={5}>
                  {data.agency_id?.agency_profile?.email}
                </MDTypography>
              </MDBox>
            </Grid>
            <Grid item xs={12}>
              <MDBox>
                <MDTypography variant="button" sx={{ fontSize: '14px' }} mb={1}>
                  Company webiste:
                </MDTypography>

                <MDTypography variant="button" sx={{ fontSize: '14px', fontWeight: '500' }} mb={1} ml={5}>
                  {data.agency_id?.agency_profile?.company_webiste}
                </MDTypography>
              </MDBox>
            </Grid>
            <Grid item xs={12}>
              <MDBox>
                <MDTypography variant="button" sx={{ fontSize: '14px' }} mb={1}>
                  Company time_zone:
                </MDTypography>

                <MDTypography variant="button" sx={{ fontSize: '14px', fontWeight: '500' }} mb={1} ml={5}>
                  {data.agency_id?.agency_profile?.time_zone}
                </MDTypography>
              </MDBox>
            </Grid>
            <Grid item xs={12}>
              <MDBox>
                <MDTypography variant="button" sx={{ fontSize: '14px' }} mb={1}>
                  Plan Type:
                </MDTypography>

                <MDTypography variant="button" sx={{ fontSize: '14px', fontWeight: '500' }} mb={1} ml={5}>
                  {data.agency_id?.type}
                </MDTypography>
              </MDBox>
            </Grid>
            <Grid item xs={12}>
              <MDBox>
                <MDTypography variant="button" sx={{ fontSize: '14px' }} mb={1}>
                  Customer Domain:
                </MDTypography>

                <MDTypography variant="button" sx={{ fontSize: '14px', fontWeight: '500' }} mb={1} ml={5}>
                  {data.agency_id?.agency_d}
                </MDTypography>
              </MDBox>
            </Grid>
            <Grid item xs={12}>
              <MDBox>
                <MDTypography variant="button" sx={{ fontSize: '14px' }} mb={1}>
                  Subscription Plan:
                </MDTypography>

                <MDTypography variant="button" sx={{ fontSize: '14px', fontWeight: '500' }} mb={1} ml={5}>
                  {data.agency_id?.default_plan}
                </MDTypography>
              </MDBox>
            </Grid>
          </Grid>
          <MDBox display="flex" justifyContent="flex-end">
            <MDButton
              variant="gradient"
              color="primary"
              type="button"
              sx={{ mt: 4, mb: 1 }}
              onClick={closeViewProduct}
            >
              close
            </MDButton>
          </MDBox>
        </MDBox>
      </MDModal>
    )
  }
  const closeViewProduct = () => {
    // if (subscription?._id)
    setOpenViewProduct(false)
  }
  return (
    <>
      <MDButton
        size="small"
        sx={{ marginRight: 2 }}
        color="info"
        variant="contained"
        onClick={handlemodal}
      >
        View
      </MDButton>
      <ViewProduct open={openViewProduct} onClose={closeViewProduct} />
    </>
  )
}

export default ViewModal
