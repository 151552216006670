/**
=========================================================
* MD UI Dashboard PRO React - v4.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/MD-ui-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the MDware.
*/
import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
import { useAppServices } from 'hook/services'
import { useEffect, useState } from 'react'
import EditModal from '../../EditModal'
import ViewModal from '../../ViewModal'

export default function DataTableData() {
  const AppService = useAppServices()
  const [List, setList] = useState([])
  const [products, setproducts] = useState([])
  const [Categories, setCategories] = useState([])
  const [loader, setloader] = useState(true)

  const getUsers = async () => {
    const { response } = await AppService.user.filter({
      role: 'agency',
    })
    console.log(response.data, 'response')
    if (response) {
      setList(response.data)
    }
    setloader(false)
  }
  const onLoad = () => {
    getUsers()
    // getProducts/()
    // getApps()
  }
  const handleDelete = async (id) => {
    const { response } = await AppService.app.delete({
      query: `_id=${id}`,
    })
    if (response) onLoad()
  }

  useEffect(onLoad, [])
  return {
    loader: loader,
    products: products,
    Categories: Categories,
    handleRefresh: onLoad,
    dataTableData: {
      columns: [
        { Header: 'Name', accessor: 'name' },
        { Header: 'Email', accessor: 'email' },
        { Header: 'Action', accessor: 'action' },
      ],

      rows: List.map((data) => ({
        name: data.username,
        email: data.email,
        // votes: data.votes,
        action: (
          <MDBox>
            <ViewModal
              products={products}
              data={data}
              handleRefresh={onLoad}
              Categories={Categories}
            />
            {/* <MDButton
              variant="contained"
              color="error"
              size="small"
              sx={{ marginLeft: 2 }}
              onClick={() => handleDelete(data._id)}
            >
              Delete
            </MDButton> */}
          </MDBox>
        ),
      })),
    },
  }
}
