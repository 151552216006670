import MDBox from 'components/MDBox'
import Button from '@mui/material/Button'
import { Grid } from '@mui/material'
import MDTypography from 'components/MDTypography'
import Typography from '@mui/material/Typography'
import localforage from 'localforage'
import React, { useEffect } from 'react'
import MDButton from 'components/MDButton'
import Singleselect from './inputs/Singleselect'
import Multiselect from './inputs/Multiselect'
import Alert from '@mui/material/Alert'
import Stack from '@mui/material/Stack'
import env from 'config'
import { useAppServices } from 'hook/services'
import Loader from 'examples/Loader'
const AppSetup = () => {
  const AppService = useAppServices()
  const [workflows_data, setworkflows_data] = React.useState([])
  const [stripe_data, setstripe_data] = React.useState([])
  const [app_setup, setapp_setup] = React.useState({})
  const [customFields_data, setcustomFields_data] = React.useState([])
  const [processing, setProcessing] = React.useState(false)
  const [loader, setloader] = React.useState(true)


  const getAppSetup = async () => {
    const { response } = await AppService.app_setup.get();
    console.log(response, "getAppSetup");

    if (response) {
      setworkflows_data(response.workflows)
      setcustomFields_data(response.customFields.map((item) => ({
        ...item,
        id: `${item.id}-${item.fieldKey.split('.')[1]}`
      })))
      setapp_setup(response.data)
      setloader(false)
    } else {
      setloader(false)

    }

  }
  const onLoad = () => {
    getAppSetup()
  }
  useEffect(() => {
    onLoad()
  }, [])
  const handleSubmit = async (e) => {
    e.preventDefault()
    setProcessing(true)

    const payload = {
      // stripe: e.target.stripe.value,
      // change_password: e.target.change_password.value,
      premium_workflow: e.target.premium_workflow.value,
      // defeult_password: e.target.defeult_password.value,
      // forgot_password: e.target.forgot_password.value,
      // agency_id: e.target.agency_id.value,
      stripe_customer: e.target.stripe_customer.value,
      subscription: e.target.subscription.value,
      // user_id: e.target.user_id.value,
    }
    console.log(payload)
    if (app_setup?._id) {
      payload._id = app_setup._id
    }
    const { response } = await AppService.app_setup.create({ payload });
    console.log(response, "response");
    if (response) {
      setProcessing(false)

    } else {
      setProcessing(false)

    }
  }
  return (
    <MDBox pb={3}>
      {
        loader ?
          <Loader />
          :
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <form onSubmit={handleSubmit}>
                {/* <Grid container mt={5} mb={2} justifyContent="center">
                  <Grid item xs={11}>
                    <MDBox pt={2} px={3}>
                      <MDTypography id="modal-modal-title" sx={{ mb: 1 }} variant="h6" component="h2">
                        Stripe Prices
                      </MDTypography>
                      <Multiselect data={stripe_data} name="stripe" />
                    </MDBox>
                  </Grid>
                </Grid> */}
                {/* <Grid container mt={2} mb={2} justifyContent="center">
                  <Grid item xs={11}>
                    <MDBox px={3}>
                      <MDTypography id="modal-modal-title" sx={{ mb: 1 }} variant="h6" component="h2">
                        API Verison
                      </MDTypography>
                      <input
                        type="text"
                        defaultValue={app_setup?.preview_link}
                        className=" form-control"
                        name="api_version"
                        placeholder="Active API Version"
                      />
                    </MDBox>
                  </Grid>
                </Grid>
                <Grid container mt={2} mb={2} justifyContent="center">
                  <Grid item xs={11}>
                    <MDBox px={3}>
                      <MDTypography id="modal-modal-title" sx={{ mb: 1 }} variant="h6" component="h2">
                        Cost Per API Call
                      </MDTypography>
                      <input
                        type="number"
                        defaultValue={app_setup?.preview_link}
                        className=" form-control"
                        name="api_version"
                        placeholder="Cost Per API Call"
                        step="0.01"
                      />
                    </MDBox>
                  </Grid>
                </Grid> */}
                {/* <Grid container mt={2} mb={2} justifyContent="center">
                  <Grid item xs={11}>
                    <MDBox px={3}>
                      <MDTypography id="modal-modal-title" sx={{ mb: 1 }} variant="h6" component="h2">
                        Change Password
                      </MDTypography>
                      <Singleselect data={workflows_data}
                        edit_data={app_setup?.change_password}

                        name="change_password" />
                    </MDBox>
                  </Grid>
                </Grid> */}
                <Grid container mt={2} mb={2} justifyContent="center">
                  <Grid item xs={11}>
                    <MDBox px={3}>
                      <MDTypography id="modal-modal-title" sx={{ mb: 1 }} variant="h6" component="h2">
                        Premium Workflows
                      </MDTypography>
                      <Singleselect
                        data={workflows_data}
                        edit_data={app_setup?.premium_workflow}

                        name="premium_workflow"
                      />
                    </MDBox>
                  </Grid>
                </Grid>
                {/* <Grid container mt={2} mb={2} justifyContent="center">
                  <Grid item xs={11}>
                    <MDBox px={3}>
                      <MDTypography id="modal-modal-title" sx={{ mb: 1 }} variant="h6" component="h2">
                        Forgot Password Workflows
                      </MDTypography>
                      <Singleselect
                        data={workflows_data}
                        edit_data={app_setup?.forgot_password}

                        name="forgot_password"
                      />
                    </MDBox>
                  </Grid>
                </Grid> */}
                {/* <Grid container mt={2} mb={2} justifyContent="center">
                  <Grid item xs={11}>
                    <MDBox px={3}>
                      <MDTypography id="modal-modal-title" sx={{ mb: 1 }} variant="h6" component="h2">
                        Default Password
                      </MDTypography>
                      <Singleselect
                        data={customFields_data}
                        edit_data={app_setup?.defeult_password}

                        name="defeult_password"
                      />
                    </MDBox>
                  </Grid>
                </Grid> */}
                {/* <Grid container mt={2} mb={2} justifyContent="center">
                  <Grid item xs={11}>
                    <MDBox px={3}>
                      <MDTypography id="modal-modal-title" sx={{ mb: 1 }} variant="h6" component="h2">
                        Agency Account ID
                      </MDTypography>
                      <Singleselect
                        data={customFields_data}
                        edit_data={app_setup?.agency_id}
                        name="agency_id"
                      />
                    </MDBox>
                  </Grid>
                </Grid> */}
                {/* <Grid container mt={2} mb={2} justifyContent="center">
                  <Grid item xs={11}>
                    <MDBox px={3}>
                      <MDTypography id="modal-modal-title" sx={{ mb: 1 }} variant="h6" component="h2">
                        User ID
                      </MDTypography>
                      <Singleselect
                        data={customFields_data}
                        edit_data={app_setup?.user_id}
                        name="user_id"
                      />
                    </MDBox>
                  </Grid>
                </Grid> */}
                <Grid container mt={2} mb={2} justifyContent="center">
                  <Grid item xs={11}>
                    <MDBox px={3}>
                      <MDTypography id="modal-modal-title" sx={{ mb: 1 }} variant="h6" component="h2">
                        Stripe Customer ID
                      </MDTypography>
                      <Singleselect
                        data={customFields_data}
                        edit_data={app_setup?.stripe_customer}
                        name="stripe_customer"
                      />
                    </MDBox>
                  </Grid>
                </Grid>
                <Grid container mt={2} mb={2} justifyContent="center">
                  <Grid item xs={11}>
                    <MDBox px={3}>
                      <MDTypography id="modal-modal-title" sx={{ mb: 1 }} variant="h6" component="h2">
                        Subscription ID
                      </MDTypography>
                      <Singleselect
                        data={customFields_data}
                        edit_data={app_setup?.subscription}
                        name="subscription"
                      />
                    </MDBox>
                  </Grid>
                </Grid>
                {/* workflow */}


                {/* ew */}
                <Grid container mt={3} mb={2} justifyContent="center">
                  <Grid item xs={11}>
                    <MDBox px={3} className="d-flex justify-content-end">
                      {!app_setup?._id ? (
                        <>
                          <MDButton
                            variant="gradient"
                            color="info"
                            type="submit"
                            loading={processing}
                            disabled={processing}
                          >
                            Save
                          </MDButton>
                        </>
                      ) : (
                        <>
                          <MDButton
                            variant="gradient"
                            color="info"
                            type="submit"
                            loading={processing}
                            disabled={processing}
                          >
                            Update
                          </MDButton>
                        </>
                      )}
                    </MDBox>
                  </Grid>
                </Grid>
              </form>
            </Grid>
          </Grid>
      }
    </MDBox>
  )
}

export default AppSetup
