/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Icon from '@mui/material/Icon'

// Material Dashboard 2 React components
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import MDAvatar from 'components/MDAvatar'
import MDProgress from 'components/MDProgress'

// Images
import LogoAsana from 'assets/images/small-logos/logo-asana.svg'
import logoGithub from 'assets/images/small-logos/github.svg'
import logoAtlassian from 'assets/images/small-logos/logo-atlassian.svg'
import logoSlack from 'assets/images/small-logos/logo-slack.svg'
import logoSpotify from 'assets/images/small-logos/logo-spotify.svg'
import logoInvesion from 'assets/images/small-logos/logo-invision.svg'
import { useAppServices } from 'hook/services'
import { useState, useEffect } from 'react'
import MDButton from 'components/MDButton'
import { useLocation, useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'

export default function data() {
  const { state } = useLocation()
  const AppService = useAppServices()
  const Navigate = useNavigate()
  const [loader, setLoader] = useState(true)
  const [SubCategory, setSubCategory] = useState([])

  const handleDelete = async (id, idx) => {
    Swal.fire({
      title: 'Warning',
      text: 'Are you sure you want to delete?',
      icon: 'warning',
      showCancelButton: true,
      showConfirmButton: true,
      confirmButtonText: 'Yes',
    }).then(async (res) => {
      if (!res.isConfirmed) return
      const { response } = await AppService.agency_articles_sub_category.delete({
        query: `_id=${id}`,
        toaster: true,
      })
      if (response) {
        SubCategory.splice(idx, 1)
        setSubCategory([...SubCategory])
      }
    })
  }
  const getDocsSubCat = async () => {
    const { response } = await AppService.agency_articles_sub_category.filter({
      query: `category_id=${state._id}`,
    })
    console.log(response, 'response')
    if (response) setSubCategory(response.data)
    setLoader(false)
  }
  const onLoad = () => {
    // alert(state._id)
    getDocsSubCat()
  }
  useEffect(onLoad, [])

  const HandleUpdate = (item) => {
    Navigate('./update', { state: { doc: item, category: state } })
  }

  return {
    getDocsSubCat: getDocsSubCat,
    columns: [
      { Header: '', accessor: 'order', width: '10%', align: 'left' },
      { Header: 'title', accessor: 'title', width: '30%', align: 'left' },
      { Header: 'action', accessor: 'action', align: 'center' },
    ],

    rows: SubCategory.map((item, idx) => ({
      order: (
        <MDBox sx={{ cursor: 'pointer' }}>
          <Icon>menu</Icon>
        </MDBox>
      ),
      title: (
        <MDTypography variant="caption" color="text" fontWeight="medium">
          {' '}
          {item.name}{' '}
        </MDTypography>
      ),

      action: (
        <>
          <MDButton
            onClick={() => handleOnClick(item, idx)}
            variant="gradient"
            color="warning"
            style={{ marginRight: '10px' }}
          >
            Edit{' '}
          </MDButton>
          <MDButton
            variant="gradient"
            color="warning"
            style={{ marginRight: '10px' }}
            onClick={() => handleDelete(item._id, idx)}
          >
            delete
          </MDButton>
          <MDButton
            variant="gradient"
            color="warning"
            style={{ marginRight: '10px' }}
            onClick={() => Navigate('./docsinsight', { state: item })}
          >
            view
          </MDButton>
          {/* {selected == item && (
          <Category category={selected} open={open === idx} onClose={handleClose} />
        )} */}
        </>
      ),
    })),
  }
}
