import axios from 'axios'
import env from 'config'
import { setNotification, useNotifcation } from 'context/notification'
import { ReqMethods, ResponseStatus } from 'enums'
import { useAuth, useLogout } from './auth'
const BASE_URL = `${env.API_URL}/v1`;

function useServiceHandler() {
  const [, dispatch] = useNotifcation()
  const Logout = useLogout()

  return (fn) =>
    async (method, path, { query, payload, token, apiKey, toaster, message, error } = {}) => {
      try {
        const res = await fn(method, path, { query, payload, token, apiKey })
        console.log('API - RESPONSE', res, toaster, message, error)
        toaster &&
          setNotification(dispatch, {
            open: true,
            message: message || res.data.message,
            title: 'Success',
            severity: 'success',
          })

        return { response: res.data }
      } catch (err) {
        console.log('API- ERROR', err.response?.data || err)

        // expire error : jwt expired
        if (err.response && err.response.status === ResponseStatus.UNAUTHORIZED) {
          setNotification(dispatch, {
            open: true,
            message: error || 'session expired',
            title: 'Error',
            severity: 'error',
          })
          setTimeout(Logout, 4000)
          return { error: err.response?.data || err }
        }

        const customError = err.response?.data?.error
          ? `${err.response?.data?.message} \n${err.response?.data?.error}`
          : err.response?.data?.message

        toaster &&
          setNotification(dispatch, {
            open: true,
            message: error || customError || err.message,
            severity: 'error',
            title: 'Error',
          })
        return { error: err.response ? err.response.data : err }
      }
    }
}

function useCallService() {
  const authToken = useAuth()
  const serviceHandler = useServiceHandler()

  const CallService = (method, path, { query, payload, token = true, apiKey = null }) => {
    const pathname = query ? `${path}?${query}` : path
    const config = {}

    if (token) config.headers = { 'x-auth-token': `Bearer ${authToken || token}` }
    if (apiKey) config.headers = { apiKey }

    const details = {}

    if (payload) details.payload = payload
    details.config = config

    return axios[method](pathname, ...Object.values(details))
  }

  return serviceHandler(CallService)
}

function useAppServices() {
  const { GET, POST, PUT, DELETE } = ReqMethods
  const CallService = useCallService()

  /**
    Option for service is the object that could has the following properties
    query, payload, token, apiKey
  */

  const APIs = {
    auth: {
      login: (options) => CallService(POST, `${BASE_URL}/auth/login`, options),
      CreateTeam: (options) => CallService(POST, `${BASE_URL}/auth/team`, options),
      GetTeam: (options) => CallService(GET, `${BASE_URL}/auth/team`, options),
    },
    user: {
      get: (options) => CallService(GET, `${BASE_URL}/user`, options),
      filter: (options) => CallService(GET, `${BASE_URL}/user/filter`, options),
      GetTeam: (options) => CallService(GET, `${BASE_URL}/user/teams`, options),
    },
    app: {
      get: (options) => CallService(GET, `${BASE_URL}/app`, options),
      update: (options) => CallService(PUT, `${BASE_URL}/app`, options),
      create: (options) => CallService(POST, `${BASE_URL}/app`, options),
      delete: (options) => CallService(DELETE, `${BASE_URL}/app`, options),
      filter: (options) => CallService(GET, `${BASE_URL}/app/filter`, options),
    },
    sso: {
      getlocations: (options) => CallService(GET, `${BASE_URL}/sso/locations`, options),
      delete: (options) => CallService(DELETE, `${BASE_URL}/sso`, options),

    },

    prospective: {
      get: (options) => CallService(GET, `${BASE_URL}/prospective`, options),
      update: (options) => CallService(PUT, `${BASE_URL}/prospective`, options),
      filter: (options) => CallService(GET, `${BASE_URL}/prospective/filter`, options),
      getpurchasedata: (options) =>
        CallService(GET, `${BASE_URL}/prospective/getpurchasedata`, options),
      user: (options) => CallService(GET, `${BASE_URL}/prospective/user`, options),
    },
    vote: {
      get: (options) => CallService(GET, `${BASE_URL}/vote`, options),
      getappvotes: (options) => CallService(GET, `${BASE_URL}/vote/getappvotes`, options),
      update: (options) => CallService(PUT, `${BASE_URL}/vote`, options),
      create: (options) => CallService(POST, `${BASE_URL}/vote`, options),
      delete: (options) => CallService(DELETE, `${BASE_URL}/vote`, options),
      filter: (options) => CallService(GET, `${BASE_URL}/vote/filter`, options),
    },
    agency_articles: {
      createDocs: (options) => CallService(POST, `${BASE_URL}/agency_articles`, options),
      updateDocs: (options) => CallService(PUT, `${BASE_URL}/agency_articles`, options),
      Docs: (options) => CallService(POST, `${BASE_URL}/agency_articles`, options),
      get_filter: (options) => CallService(GET, `${BASE_URL}/agency_articles/filter`, options),
      get_filter_cat: (options) =>
        CallService(GET, `${BASE_URL}/agency_articles/category/filter`, options),
      delete: (options) => CallService(DELETE, `${BASE_URL}/agency_articles/delete`, options),
    },
    agency_articles_category: {
      createDocs: (options) => CallService(POST, `${BASE_URL}/agency_articles/category`, options),
      updateDocs: (options) => CallService(PUT, `${BASE_URL}/agency_articles/category`, options),
      delete: (options) =>
        CallService(DELETE, `${BASE_URL}/agency_articles/category/delete`, options),
    },
    agency_articles_sub_category: {
      create: (options) => CallService(POST, `${BASE_URL}/agency_articles/subcategory`, options),
      update: (options) => CallService(PUT, `${BASE_URL}/agency_articles/subcategory`, options),
      filter: (options) =>
        CallService(GET, `${BASE_URL}/agency_articles/subcategory/filter`, options),
      delete: (options) =>
        CallService(DELETE, `${BASE_URL}/agency_articles/subcategory/delete`, options),
    },
    products: {
      createProduct: (options) => CallService(POST, `${BASE_URL}/products`, options),
      updateProduct: (options) => CallService(PUT, `${BASE_URL}/products`, options),
      getProducts: (options) => CallService(GET, `${BASE_URL}/products/filter`, options),
      deleteProduct: (options) => CallService(DELETE, `${BASE_URL}/products/delete`, options),
    },
    docs: {
      createDocs: (options) => CallService(POST, `${BASE_URL}/prompts`, options),
      updateDocs: (options) => CallService(PUT, `${BASE_URL}/prompts`, options),
      Docs: (options) => CallService(POST, `${BASE_URL}/prompts`, options),
      get_filter: (options) => CallService(GET, `${BASE_URL}/prompts/filter`, options),
      get_filter_cat: (options) => CallService(GET, `${BASE_URL}/prompts/category/filter`, options),
      delete: (options) => CallService(DELETE, `${BASE_URL}/prompts/delete`, options),
    },
    docs_category: {
      createDocs: (options) => CallService(POST, `${BASE_URL}/prompts/category`, options),
      updateDocs: (options) => CallService(PUT, `${BASE_URL}/prompts/category`, options),
      delete: (options) => CallService(DELETE, `${BASE_URL}/prompts/category/delete`, options),
    },
    docs_sub_category: {
      create: (options) => CallService(POST, `${BASE_URL}/prompts/subcategory`, options),
      update: (options) => CallService(PUT, `${BASE_URL}/prompts/subcategory`, options),
      filter: (options) => CallService(GET, `${BASE_URL}/prompts/subcategory/filter`, options),
      delete: (options) => CallService(DELETE, `${BASE_URL}/prompts/subcategory/delete`, options),
    },
    request_app: {
      get: (options) => CallService(GET, `${BASE_URL}/requestapp`, options),
      update: (options) => CallService(PUT, `${BASE_URL}/requestapp`, options),
      create: (options) => CallService(POST, `${BASE_URL}/requestapp`, options),
      delete: (options) => CallService(DELETE, `${BASE_URL}/requestapp`, options),
      filter: (options) => CallService(GET, `${BASE_URL}/requestapp/filter`, options),
    },
    categories: {
      get: (options) =>
        CallService(GET, `${BASE_URL}/snapshot/category/getsnaphotcategories`, options),
      update: (options) =>
        CallService(POST, `${BASE_URL}/snapshot/category/update/${options.id}`, options),
      create: (options) => CallService(POST, `${BASE_URL}/snapshot/category/submit`, options),
      delete: (options) =>
        CallService(POST, `${BASE_URL}/snapshot/category/delete/${options.id}`, options),
      filter: (options) => CallService(GET, `${BASE_URL}/snapshot/category/filter`, options),
    },
    offer: {
      get: (options) => CallService(GET, `${BASE_URL}/offer`, options),
      update: (options) => CallService(PUT, `${BASE_URL}/offer`, options),
      create: (options) => CallService(POST, `${BASE_URL}/offer`, options),
      delete: (options) => CallService(DELETE, `${BASE_URL}/offer`, options),
      filter: (options) => CallService(GET, `${BASE_URL}/offer/filter`, options),
    },
    super_admin_settings: {
      get: (options) => CallService(GET, `${BASE_URL}/super_admin_settings`, options),
      update: (options) => CallService(PUT, `${BASE_URL}/super_admin_settings`, options),
      create: (options) => CallService(POST, `${BASE_URL}/super_admin_settings`, options),
      delete: (options) => CallService(DELETE, `${BASE_URL}/super_admin_settings`, options),
      filter: (options) => CallService(GET, `${BASE_URL}/super_admin_settings/filter`, options),
    },
    app_setup: {
      get: (options) => CallService(GET, `${BASE_URL}/app_setup`, options),
      update: (options) => CallService(PUT, `${BASE_URL}/app_setup`, options),
      create: (options) => CallService(POST, `${BASE_URL}/app_setup`, options),
      delete: (options) => CallService(DELETE, `${BASE_URL}/app_setup`, options),
      filter: (options) => CallService(GET, `${BASE_URL}/app_setup/filter`, options),
    },
    snapshot: {
      get: (options) => CallService(GET, `${BASE_URL}/offer`, options),
      update: (options) => CallService(PUT, `${BASE_URL}/offer`, options),
      create: (options) => CallService(POST, `${BASE_URL}/snapshot/create_snapshot`, options),
      delete: (options) => CallService(DELETE, `${BASE_URL}/offer`, options),
      findbyquery: (options) =>
        CallService(GET, `${BASE_URL}/snapshot/create_snapshot/findbyquery`, options),
    },
    brand: {
      get: (options) => CallService(GET, `${BASE_URL}/brand`, options),
      update: (options) => CallService(PUT, `${BASE_URL}/brand`, options),
      superadmin: (options) => CallService(PUT, `${BASE_URL}/brand/superadmin`, options),
      filter: (options) => CallService(GET, `${BASE_URL}/brand/filter`, options),
    },
    agency: {
      get: (options) => CallService(GET, `${BASE_URL}/agency`, options),
      runWorkflow: (options) => CallService(POST, `${BASE_URL}/agency/workflow`, options),
      update: (options) => CallService(PUT, `${BASE_URL}/agency`, options),
      getProducts: (options) => CallService(PUT, `${BASE_URL}/agency/getproducts`, options),
    },
    superadmin: {
      getProducts: (options) =>
        CallService(GET, `${BASE_URL}/superadmin_settings/workflows`, options),
    },
    utils: {
      upload_image: (options) => CallService(POST, `${BASE_URL}/utils/upload/image`, options),
    },
    stripe: {
      // product: (options) => CallService(GET, `${BASE_URL}/services/stripe/products`, options),
      // getCustomer: (options) => CallService(GET, `${BASE_URL}/services/stripe/customers`, options),
      integrateAcocunt: (options) =>
        CallService(GET, `${BASE_URL}/snapshot/agency/stripe/integrate`, options),
      // addSubscription: (options) =>
      //   CallService(POST, `${BASE_URL}/services/stripe/subscription/add`, options),
    },
    services: {
      ghl: {
        call_service: (options) => CallService(POST, `${BASE_URL}/services/ghl/`, options),
      },
    },
    toneoptions: {
      create: (options) => CallService(POST, `${BASE_URL}/toneoptions/create`, options),
      Get: (options) => CallService(GET, `${BASE_URL}/toneoptions`, options),
      update: (options) => CallService(PUT, `${BASE_URL}/toneoptions`, options),
      Delete: (options) => CallService(DELETE, `${BASE_URL}/toneoptions`, options),
    },
    translateoption:{
      create: (options) => CallService(POST, `${BASE_URL}/translateoption/create`, options),
      Get: (options) => CallService(GET, `${BASE_URL}/translateoption`, options),
      update: (options) => CallService(PUT, `${BASE_URL}/translateoption`, options),
      Delete: (options) => CallService(DELETE, `${BASE_URL}/translateoption`, options),
    },
    imgoptions:{
      create: (options) => CallService(POST, `${BASE_URL}/imgoptions/create`, options),
      Get: (options) => CallService(GET, `${BASE_URL}/imgoptions`, options),
      update: (options) => CallService(PUT, `${BASE_URL}/imgoptions`, options),
      Delete: (options) => CallService(DELETE, `${BASE_URL}/imgoptions`, options),
    },
    channeloptions:
    {
      create: (options) => CallService(POST, `${BASE_URL}/channeloptions/create`, options),
      Get: (options) => CallService(GET, `${BASE_URL}/channeloptions`, options),
      update: (options) => CallService(PUT, `${BASE_URL}/channeloptions`, options),
      Delete: (options) => CallService(DELETE, `${BASE_URL}/channeloptions`, options),
    },
    modalcreate:
    {
      create: (options) => CallService(POST, `${BASE_URL}/modalcreate/create`, options),
      modalsetdefault: (options) => CallService(POST, `${BASE_URL}/modalcreate/modalsetdefault`, options),
      Get: (options) => CallService(GET, `${BASE_URL}/modalcreate`, options),
      update: (options) => CallService(PUT, `${BASE_URL}/modalcreate`, options),
      Delete: (options) => CallService(DELETE, `${BASE_URL}/modalcreate`, options),
    },
  }

  return APIs
}
const useUploadImage = () => {
  const AppService = useAppServices()
  return ({ toaster, file, desiredPath }) => {
    const form = new FormData()
    form.append('image', file, file.name)
    return AppService.utils.upload_image({
      toaster,
      payload: form,
      query: `desiredPath=${desiredPath}`,
    })
  }
}
export { useAppServices, useCallService, useUploadImage }
